(function (doc, win) {
	        var docEl = doc.documentElement,
	            resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',//????????
	            readyEvt = 'DOMContentLoaded' in window ? 'DOMContentLoaded' : 'readystatechange',//DOM??????,ie8??readystatechange
	            recalc = function () {
	                var clientWidth = docEl.clientWidth;
	                //console.log(readyEvt + "*************" + resizeEvt + "*************" + clientWidth)
	                if (!clientWidth) return;
	                if( clientWidth >= 1920 ){//???????????
	                    docEl.style.fontSize = '100px';
	                }else{
	                    docEl.style.fontSize = 100 * (clientWidth / 1920) + 'px';
	                };
	            };

	        if (doc.addEventListener) {//IE8???addEventListener??
	        	doc.addEventListener(readyEvt, recalc, false);//?????
	        	win.addEventListener(resizeEvt, recalc, false);//????????????
	        }else{//??IE8???attachEvent
				doc.attachEvent("onreadystatechange", recalc);//?????
		        win.attachEvent("onresize", recalc);//????????????
	        };
	        
		})(document, window);
		