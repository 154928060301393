import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import set from 'lodash/set'

class HttpUtils {
    constructor (baseUrl) {
        let baseURL
        if (baseUrl.startsWith('http') || baseUrl.startsWith('ws') || baseUrl.startsWith('ftp')) {
            baseURL = baseUrl
        } else {
            baseURL = window.location.origin + baseUrl
        }
        let baseConfig = {
            baseURL: baseURL
        }
        this._instance = axios.create(baseConfig)
    }
    /**
     * 通用请求
     * @param method
     * @param url
     * @param data
     * @returns {Promise<T>}
     */
    _base (method, url, data, errorConfig) {
        method = method.toUpperCase()
        let config = {
            method,
            url,
            data: method === 'GET' ? null : data,
            params: method === 'GET' ? data : null,
            timeout: 180000
        }
        // 默认错误配置
        let defaultErrorConfig = {
            // 不显示任何错误信息
            noErrorMessage: false,
            // 自定义的错误返回信息
            message: undefined
        }
       
        errorConfig = Object.assign(cloneDeep(defaultErrorConfig), errorConfig)
        let cast = this._instance.request(config)
        // 对请求结果进行二次处理，处理掉错误信息，并返回错误内容
        cast.catch(err => {
            // 如果配置不需要任务报错提醒
            if (errorConfig.noErrorMessage) {
                return err
            }
            // 如果有自定义错误信息，全局返回错误的自定义信息
            if (errorConfig.message) {
                // Notice.error({title: errorConfig.message})
                return err
            }
            // 如果 状态码大于500，多是后台程序错误，直接提示，如果response不存在，一般是跨域导致的
            if (get(err, 'response.status', 500) >= 500) {
                // Notice.error({title: '请求异常!'})
                return err
            }
            // 如果有错误码，按照错误码显示错误,如果没有，直接返回系统异常
            if (err.errorCode) {
                // Notice.error({title: getCodeMessage(err.errorCode)})
            } else {
                // Notice.error({title: '请求异常!'})
            }
            return err
        })
        return cast
    }
    /**
     * 简化 try catch写法
     * @param promise
     * @param simple 是否是简单模式，简单模式：res.data;非简单模式：[err,data]
     * @returns {Promise<T>}
     */
    _to (promise, simple = false) {
        return promise.then(res => {
            if (simple) {
                // if(res.data.code === 401){
                //     localStorage.removeItem('userInfo')
                //     // localStorage.removeItem('productDict')
                //     window.location.href="http://cec.theiavis.com/login"
                // } 
                return res.data
            } else {
                return [null, res]
            }
        }).catch(err => {
            if (simple) {
                return null
            } else {
                return [err, null]
            }
        })
    }
    /**
     * get
     * @param url
     * @param data
     * @returns {Promise<T>}
     */
    get (url, data, errorConfig) {
        set(errorConfig, 'noErrorMessage', false)
        return this._to(this._base('get', url, data, errorConfig), true)
    }
    post (url, data, errorConfig) {
        set(errorConfig, 'noErrorMessage', false)
        return this._to(this._base('post', url, data, errorConfig), true)
    }
    // post ()
}
export default HttpUtils
