import axios from 'axios'
import React, {Component} from 'react';
// import {inject, observer} from 'mobx-react';
import { observable , action,computed} from 'mobx';
class TestStore {
    @observable CoreList = []; 
    @observable ProductLists = []; 
    @action changeCoreList=info=> {
      this.CoreList = info;
    }
    @action changeProductList=infos=> {
       this.ProductLists = infos;
    }
}
const test = new TestStore() ;
export default test;